import { useParams } from 'next/navigation';
import { useGlobalStore } from '@/app/contexts/global/globalContext';

export const usePartner = () => {
  const { state } = useGlobalStore();

  const params = useParams();

  const currentPartner = state.partnerForAuthenticatedMerchant ?? ((params.partner as string) || 'liberis');
  return currentPartner;
};
